import React, { useEffect } from "react";
import ContactVideo from "../images/video/dj_video.mp4";
import AOS from "aos";
import "aos/dist/aos.css";

function Contact() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div
        className="flex flex-col items-center mt-10 min-h-[100vh]"
        data-aos="fade-in"
      >
        <div className="flex flex-col md:flex-row">
          <div className="mb-5 text-center mx-5">
            <h1 className="text-5xl">contact</h1>
            <a href="mailto:contact@momentone.org">
              <h6 className="text-2xl text-blue-800 hover:text-blue-900 transition-300">
                contact@momentone.org
              </h6>
            </a>
          </div>
          <ul className="text-center mb-5 mx-5">
            <li>
              <h1 className="text-5xl">adress</h1>
            </li>
            <li>
              <h6 className="text-2xl">Barnhusgatan 20</h6>
            </li>
            <li>
              <h6 className="text-2xl">111 23</h6>
            </li>

            <li>
              <h6 className="text-2xl">Stockholm Sweden</h6>
            </li>
          </ul>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2034.8415422379228!2d18.05137701225252!3d59.33559931041345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d66a3ea6ec9%3A0xb0fc18140a015fb3!2sBarnhusgatan%2020%2C%20111%2023%20Stockholm!5e0!3m2!1ssv!2sse!4v1680871540087!5m2!1ssv!2sse"
          className="mb-5 w-[90%] sm:w-[400px] h-[400px]"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          data-aos="fade-in"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;

import React from "react";
import "../App.css";
import {
  FaInstagram,
  FaFacebook,
  FaSoundcloud,
  FaTwitter,
} from "react-icons/fa";
import ElsaImage from "../images/elsa_profile_1.webp";
function Elsa1() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Elsa Carmona "Sirena" Oljelund</h1>
          <br />
          <h3 className="text-3xl italic">Songwriter</h3>
          <br />
          <p>
            Elsa Carmona Oljelund is a Songwriter from Stockholm, Sweden but was
            born in Barcelona. She focuses on writing music (both in english and
            spanish), within the EDM/Pop/K-Pop genre, and having been in the
            music industry for many years, she offers a great understanding of
            the industry and is known for her details in both writing lyrics and
            her singing voice.
          </p>
          <br />
          <p>
            Some of her collaborations include artists such as Iggy Azalea,
            Alesso, Noonie Bao, Charli XCX, Icona Pop, Style Of Eye, Dimitri
            Vangelis & Wyman, CLMD and many more.
          </p>
          <br />
          <p>
            Elsa is a multi platinum & gold awarded Songwriter and is also known
            under her psuedonym "Sirena".
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/sirenamusic_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={ElsaImage} className="artists__photo__size" />
          </div>
        </div>
      </div>
      <div className="spotify__playlist" data-aos="fade-right">
        <iframe
          src="https://open.spotify.com/embed/playlist/02etAZcpVA5nZ8RRDg4UEi?utm_source=generator&theme=0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          src="https://www.youtube.com/embed/thpsmZiDv6U"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          src="https://www.youtube.com/embed/H2Dmb0ccoII"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          src="https://www.youtube.com/embed/Z6hfoHXU90w"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
    </div>
  );
}

export default Elsa1;

import React from "react";
import "../App.css";
import {
  FaInstagram,
  FaFacebook,
  FaSoundcloud,
  FaTwitter,
} from "react-icons/fa";
import GustavImage from "../images/gustav_1.png";
function Gustav() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Gustav Nilsson</h1>
          <br />
          <h3 className="text-3xl italic">Producer / Songwriter</h3>
          <br />
          <p>
            Gustav Nilsson is a producer and songwriter from Stockholm, Sweden
            who focuses on creating music within the EDM/Pop/K- Pop genre.
            Having been in the music industry for 3 years, currently studying at
            "Musikmakarna", he offers a great understanding of the industry and
            is known for his perceptive and empathetic qualities.
          </p>
          <br />
          <p>
            Gustavs first placement was the song Tasty on "Ay-Yo - The 4th Album
            Repackage" with NCT 127 which debuted #1 on the Billboard “World
            Albums” chart.
          </p>
          <br />
          <p>
            Some of he's recent collaborations include artists such as NCT 127,
            Moonshine, Julia Lov, Emma Rammelt.
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/gustav.wav/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={GustavImage} className="artists__photo__size" />
          </div>
        </div>
      </div>

      <div className="spotify__playlist mb-5" data-aos="fade-right">
        <iframe
          src="https://open.spotify.com/embed/playlist/3ta2Ti1Gh5yIshJXMe8qGc?utm_source=generator&theme=0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
    </div>
  );
}

export default Gustav;

import React from "react";
import "../App.css";
import { FaInstagram } from "react-icons/fa";
import AdamImage from "../images/adam_profile_1.webp";

function Adam() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Adam Woods</h1>
          <br />
          <h3 className="text-3xl italic">Songwriter / Producer</h3>
          <br />
          <p>
            Adam Woods is a producer and songwriter from Stockholm, Sweden who
            focuses on creating music within the EDM/Pop/K- Pop genre. Having
            been in the music industry for many years, currently studying at
            "Musikmakarna", he offers a great understanding of the industry and
            is known for his perceptive and empathetic qualities.
          </p>
          <br />
          <p>
            Some of he's recent collaborations include artists such as
            Tungevaag, Ilkay Sencan, HVME, Besomorph, Lucas Estrada, Helion,
            Moonshine, Arc North, Cadmium and many more.
          </p>
          <br />
          <p>
            Adam competed in Melodifestivalen 2023 and took the 4th place
            together with Arc North and Jon Henrik Fjällgren.
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/imadamwoods/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={AdamImage} className="artists__photo__size" />
          </div>
        </div>
      </div>

      <div className="flex justify-center pt-10" data-aos="fade-in">
        <iframe
          src="https://open.spotify.com/embed/artist/5hmV66qKLsAbAoJcnogGyc?utm_source=generator&theme=0"
          className="w-[90%] max-w-[800px] h-[380px]"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>

      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          className="w-[90%] max-w-[800px] h-[400px]"
          src="https://www.youtube.com/embed/ZBB3fSvuq08?si=mQ00Lvkh_vrA9op1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          className="w-[90%] max-w-[800px] h-[400px]"
          src="https://www.youtube.com/embed/rgdmtFu4CYc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default Adam;

import React from "react";
import "../App.css";
import { FaInstagram, FaFacebook, FaSoundcloud } from "react-icons/fa";
import KarlImage from "../images/karl_flykt_profile.webp";
function Karl() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Karl Flykt</h1>
          <br />
          <h3 className="text-3xl italic">SONGWRITER/PRODUCER</h3>
          <br />
          <p>
            Karl Flykt is a songwriter from Stockholm, Sweden who focuses on
            creating music within the EDM/Pop/K-Pop genre. Having been in the
            music industry for 8 years, he offers a great understanding of the
            industry and is known for his perceptive, melodies and lyrics.
          </p>
          <br />
          <p>
            Some of he's recent cuts/holds include artists such as Lucas
            Estrada, Jireel, Mindme, waykap, HVME and Ilkay Sencan.
          </p>
          <br />
          <p>
            Karl is a multi platinum & gold awarded producer and songwriter.
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/karlflyckt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={KarlImage} className="artists__photo__size" />
          </div>
        </div>
      </div>
      <div className="spotify__playlist mb-5" data-aos="fade-right">
        <iframe
          src="https://open.spotify.com/embed/playlist/3na2MzrMrDEnVsS0qZOCem?utm_source=generator&theme=0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
    </div>
  );
}

export default Karl;

import React from "react";
import "../App.css";
import {
  FaInstagram,
  FaFacebook,
  FaSoundcloud,
  FaTwitter,
} from "react-icons/fa";
import SteernerImage from "../images/steerner_profile.webp";
function Steerner() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Niklas Sterner aka Steerner</h1>
          <br />
          <h3 className="text-3xl italic">Producer / Songwriter</h3>
          <br />
          <p>
            Niklas Sterner is a producer and songwriter from Stockholm, Sweden
            who focuses on creating music within the EDM/Pop genre. Having been
            in the music industry for many years, he offers a great
            understanding of the industry and is known for his detailed
            producing skills.
          </p>
          <br />
          <p>
            At the forefront of the electronic scene and with support from
            Avicii, Sebastian Ingrosso, and Tiesto to name a few, his most
            prolific release ‘Friends’ has reached over a total of 70 million
            streams – to not name his back catalog of 1.3 billion combined with
            remixes for the likes of The Chainsmokers, ILLENIUM, Alan Walker and
            Miguel. Niklas has also produced an album for Dillion Francis.
          </p>
          <br />
          <p>
            Recent collabs includes artists / writers such as Tungevaag, BUNT,
            Dillion Francis, The Him, Nause, LIAMOO, Syn Cole and many more.
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/steerner/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={SteernerImage} className="artists__photo__size" />
          </div>
        </div>
      </div>

      <div className="spotify__playlist" data-aos="fade-right">
        <iframe
          src="https://open.spotify.com/embed/playlist/37i9dQZF1DZ06evO14ofPb?utm_source=generator&theme=0"
          className="w-[90%] max-w-[800px] h-[380px]"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>

      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          loading="lazy"
          className="w-[90%] max-w-[800px] h-[400px]"
          src="https://www.youtube.com/embed/MPbNoQFFdKo?si=8JbL_d-JFJh5FPqc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          loading="lazy"
          className="w-[90%] max-w-[800px] h-[400px]"
          src="https://www.youtube.com/embed/BDszFA1hUPQ?si=5rT6T6COnSWeLghP"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default Steerner;

import JonHenrik from "../images/releases/where_you_are.png";
import Test2 from "../images/releases/candela.jpeg";
import Test3 from "../images/releases/blood_moon.jpeg";
import Test4 from "../images/releases/better_days.png";
import Tungevaan from "../images/releases/imma_love_you.jpeg";
import Vinai from "../images/releases/in_the_dark.jpeg";
import Test7 from "../images/releases/solita.jpeg";
import Test8 from "../images/releases/waiting_for_you.jpeg";
import OmarRudberg from "../images/releases/happier.jpeg";
import Test10 from "../images/releases/cowgirl.jpeg";
import Test11 from "../images/releases/high_frequency.jpg";
import Test12 from "../images/releases/varfor.jpg";
import Test13 from "../images/releases/fri.jpg";
import Test14 from "../images/releases/where_did_we_go_wrong.jpg";
import Test15 from "../images/releases/hills_in_la.png";
import Test16 from "../images/releases/sweet_karma.png";
import Test17 from "../images/releases/getting_paid.png";
import Test18 from "../images/releases/best_in_me.png";
import Test19 from "../images/releases/let_it_happen.jpg";
import Test20 from "../images/releases/jaded.png";
import Test21 from "../images/releases/one_last_time.png";
import Chelsea from "../images/releases/chelsea.png";
import AdamWoods from "../images/releases/adam_woods_3.png";
import MarcusMartinus from "../images/releases/marcus_martinus.png";
import KimCesarion from "../images/releases/kimcesarion.png";
export const images = [
  {
    imageUrl: JonHenrik,
    linkUrl: "https://www.youtube.com/watch?v=1osZym6HpVs&ab_channel=ArcNorth",
  },
  {
    imageUrl: MarcusMartinus,
    linkUrl: "https://www.youtube.com/watch?v=mW0JirtjcnU",
  },
  {
    imageUrl: AdamWoods,
    linkUrl: "https://www.youtube.com/watch?v=Z-gHlf3HO7k",
  },
  {
    imageUrl: Chelsea,
    linkUrl: "https://www.youtube.com/watch?v=3O90SzbvGPk",
  },
  {
    imageUrl: KimCesarion,
    linkUrl: "https://www.youtube.com/watch?v=yGEhz0Vfqjs",
  },
  {
    imageUrl: OmarRudberg,
    linkUrl:
      "https://www.youtube.com/watch?v=pCaN3qXnwQ4&ab_channel=OmarRudberg-Topic",
  },
  {
    imageUrl: Tungevaan,
    linkUrl:
      "https://www.youtube.com/watch?v=BDszFA1hUPQ&ab_channel=Spinnin%27Records",
  },
  {
    imageUrl: Vinai,
    linkUrl: "https://www.youtube.com/watch?v=WCzVH54ONPY&ab_channel=VINAIVEVO",
  },

  {
    imageUrl: Test2,
    linkUrl:
      "https://www.youtube.com/watch?v=5U5Gfphxcgs&ab_channel=AnotherRhythmRecords",
  },
  {
    imageUrl: Test3,
    linkUrl: "https://www.youtube.com/watch?v=bnPk-p49MJ4&ab_channel=Rival",
  },
  {
    imageUrl: Test4,
    linkUrl:
      "https://www.youtube.com/watch?v=BlqgZ8rzuWc&ab_channel=L%C3%BC-Topic",
  },

  {
    imageUrl: Test7,
    linkUrl: "https://www.youtube.com/watch?v=1rR9gDHjiNE&ab_channel=LoudKult",
  },
  {
    imageUrl: Test8,
    linkUrl:
      "https://www.youtube.com/watch?v=2KnW-LPUSJ0&ab_channel=ThierryvonderWarth-Topic",
  },
  {
    imageUrl: Test10,
    linkUrl: "https://www.youtube.com/watch?v=z4WgqDLbLCk&ab_channel=Alfons",
  },
  {
    imageUrl: Test11,
    linkUrl:
      "https://www.youtube.com/watch?v=_znCZNlyYv0&ab_channel=LucasEstrada-Topic",
  },
  {
    imageUrl: Test12,
    linkUrl:
      "https://www.youtube.com/watch?v=4EodUOGdMcM&ab_channel=TGRMusicGroup",
  },
  {
    imageUrl: Test13,
    linkUrl:
      "https://www.youtube.com/watch?v=YlqBxVIoQhA&ab_channel=AlbinJohns%C3%A9n-Topic",
  },
  {
    imageUrl: Test14,
    linkUrl: "https://www.youtube.com/watch?v=Z9sqCd8X7jY&ab_channel=Crunkz",
  },
  {
    imageUrl: Test15,
    linkUrl:
      "https://www.youtube.com/watch?v=gGS6vm5wgU4&ab_channel=LucasEstrada-Topic",
  },
  {
    imageUrl: Test16,
    linkUrl: "https://www.youtube.com/watch?v=EQQE94xZIfw&ab_channel=CloudKid",
  },
  {
    imageUrl: Test17,
    linkUrl: "https://www.youtube.com/watch?v=ZAa8FFPThAE&ab_channel=ILYAA",
  },
  {
    imageUrl: Test18,
    linkUrl: "https://www.youtube.com/watch?v=zQicxsx2B00&ab_channel=CARSTN",
  },
  {
    imageUrl: Test19,
    linkUrl:
      "https://www.youtube.com/watch?v=sCO8q9ECvP4&ab_channel=LucasEstrada-Topic",
  },
  {
    imageUrl: Test20,
    linkUrl:
      "https://www.youtube.com/watch?v=OR9Wy1M_hmU&ab_channel=LucasEstrada-Topic",
  },
  {
    imageUrl: Test21,
    linkUrl:
      "https://www.youtube.com/watch?v=k39VL3m9LcY&ab_channel=LucasEstrada-Topic",
  },
];

export const navlinks = [
  {
    title: "home",
    url: "/",
  },
  {
    title: "about",
    url: "/about",
  },
  {
    title: "writers",
    url: "/start",
  },
  {
    title: "contact",
    url: "/contact",
  },
];

import React from "react";
import { navlinks } from "../constants/Data";
import { Link } from "react-router-dom";
import MomentoneLogo from "../images/momentone_logo_black.png";
import { FaInstagram } from "react-icons/fa";
function Footer() {
  return (
    <div className="bg-white w-full min-h-[150px] __footer">
      <div className="flex justify-between items-center flex-col md:flex-row mx-10 pt-5">
        <ul className="text-center md:text-left mb-5 md:mb-0">
          <li>
            <a href="mailto:contact@momentone.org">
              <h6 className="text-lg text-blue-800 hover:text-blue-900 transition-300">
                contact@momentone.org
              </h6>
            </a>
          </li>
          <li>
            <h6 className="text-md text-black">Barnhusgatan 20</h6>
          </li>
          <li>
            <h6 className="text-md text-black">111 23</h6>
          </li>
          <li>
            <h6 className="text-md text-black">Stockholm Sweden</h6>
          </li>
        </ul>

        <ul className="flex flex-col text-center md:text-left mb-5">
          <div className="flex flex-col md:flex-row">
            {navlinks.map((navLink, index) => (
              <li key={index}>
                <Link to={navLink.url}>
                  <h1 className="text-black text-md sm:text-xl hover:opacity-70 pr-0 md:pr-4">
                    {navLink.title}
                  </h1>
                </Link>
              </li>
            ))}
          </div>
        </ul>

        <Link to="/" className="flex justify-center items-center">
          <img src={MomentoneLogo} className="w-40 hover:opacity-80" />
        </Link>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import "../App.css";
import {
  FaInstagram,
  FaFacebook,
  FaSoundcloud,
  FaTwitter,
} from "react-icons/fa";
import EliasImage from "../images/elias_profile.png";
function Alexander() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Elias Öhberg</h1>
          <br />
          <h3 className="text-3xl italic">Songwriter</h3>
          <br />
          <p>
            Elias Öberg is a songwriter from Stockholm, Sweden who focuses on
            creating music within the EDM/Pop/K-Pop genre. Having been in the
            music industry for 4 years, he has a degree in songwriting at
            "Musikmakarna", he offers a great understanding of the industry and
            is known for his perceptive, melodies and lyrics.
          </p>
          <br />
          <p>
            Elias is a multi platinum & gold awarded producer and songwriter.
          </p>
          <br />
          <p>
            Some of he's recent cuts/holds include artists such as ZEROBASEONE
            TVXQ!, HVME, Ilkay Sencan, CLMD, Vaméro, Ella Rammelt, Lucas
            Estrada, Rammor, Flakkë.
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/elias.oberg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={EliasImage} className="artists__photo__size" />
          </div>
        </div>
      </div>

      <div className="spotify__playlist" data-aos="fade-right">
        <iframe
          src="https://open.spotify.com/embed/playlist/30a32TF5yaDW7UnAiqFC1X?utm_source=generator&theme=0"
          className="w-[90%] max-w-[800px] h-[380px]"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>

      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          loading="lazy"
          className="w-[90%] max-w-[800px] h-[400px]"
          src="https://www.youtube.com/embed/gyYvNet4Krw?si=EVugTZerdcwPZYID"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default Alexander;

import React from "react";
import "../App.css";
import {
  FaInstagram,
  FaFacebook,
  FaSoundcloud,
  FaTwitter,
} from "react-icons/fa";
import ElsaImage from "../images/alexander_profile_1.webp";
function Alexander() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Alexander "Baimz" Latila</h1>
          <br />
          <h3 className="text-3xl italic">Songwriter / Producer</h3>
          <br />
          <p>
            Alexander Laitila is a producer and songwriter from Hjo, Sweden who
            focuses on creating music within the EDM/Pop/K-Pop genre. Having
            been in the music industry for many years, he offers a great
            understanding of the industry and is known for his details in both
            writing lyrics, production and he's a great multi-instrumentalist.
          </p>
          <br />
          <p>
            Some of his recent collaborations include artists such as Tungevaag
            & Raaban, Mike Williams, Ilkay Sencan, HVME, Danny Saucedo, KSHMR,
            Nicky Romero, Egzod and many more.
          </p>
          <br />
          <p>
            Alexander is a multi platinum & gold awarded producer and
            songwriter. His song "Russian Roulette" landed him a nr 1 spot on
            spotify top 50 and sold 8+ platinum.
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/itsbaimz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={ElsaImage} className="artists__photo__size" />
          </div>
        </div>
      </div>

      <div className="spotify__playlist" data-aos="fade-right">
        <iframe
          src="https://open.spotify.com/embed/playlist/6UNeZjfwSlIksT2bQvJQfV?utm_source=generator&theme=0"
          className="w-[90%] max-w-[800px] h-[380px]"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>

      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          className="w-[90%] max-w-[800px] h-[400px]"
          src="https://www.youtube.com/embed/Q5j6gDIASPE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default Alexander;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WriterVideo from "../images/video/piano_video.mp4";
import WriterImage from "../images/piano_image.webp";
import { WritersData } from "../constants/Writers.js";

import AOS from "aos";
import "aos/dist/aos.css";
import "../App.css";

function Artists() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMdOrHigher = windowWidth >= 768;

  return (
    <div>
      {/* 
      <div className="relative h-[75vh] flex items-center justify-start w-[100%] bg-black">
        <div className="absolute inset-0 z-0">
          {isMdOrHigher ? (
            <video
              src={WriterVideo}
              alt="Hero"
              loading="lazy"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              className="opacity-60"
              loop
              muted
              autoPlay
            />
          ) : (
            <img
              src={WriterImage}
              alt="Hero"
              loading="lazy"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              className="opacity-60"
            />
          )}
        </div>

        <div className="relative z-1 flex flex-col mx-5 md:ml-10">
          <h1
            className="text-7xl md:text-9xl mb-4 text-white"
            data-aos="flip-up"
          >
            Writers.
          </h1>
          <h6
            className="text-lg md:text-xl w-[90%] md:w-[80%] ml-1 text-white"
            data-aos="zoom-in-left"
          >
            Our multi-platinum writers has etched their names alongside artists
            like Alesso, Tungevaag, VINAI, Jon Henrik Fjällgren, Lucas Estrada,
            Albin Johnsén and many more.
          </h6>
        </div>
      </div>
*/}

      <div className="flex flex-col items-center justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 flex flex-col justify-center items-center">
          {WritersData.map((writer, index) => (
            <a
              key={index}
              href={writer.linkUrl}
              className="text-center shadow-lg relative"
              data-aos="fade-in"
            >
              <img
                src={writer.imageUrl}
                alt={`Image ${index + 1}`}
                className="max-w-full transition-opacity duration-300 hover:opacity-70"
                loading="lazy"
                width="600"
                height="500"
              />

              <div className="text-2xl md:text-3xl absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 duration-300 transition-opacity">
                <h6 className="text-white"> {writer.imageText}</h6>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Artists;

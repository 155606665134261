import React from "react";
import { useEffect, useState } from "react";
import { images } from "../constants/Data";

import AOS from "aos";
import "aos/dist/aos.css";

const Releases = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  //Four products visible as default.
  const [visibleProducts, setVisibleProducts] = useState(8);

  // Runs when "Show more products"-button is clicked.
  const displayMoreProducts = () => {
    setVisibleProducts((products) => products + 8);
  };

  return (
    <div>
      {/* 
      <div className="ml-10 mt-20 flex flex-col justify-center items-center">
        <div className="flex flex-col md:flex-row md:justify-evenly md:mr-5">
          <h1
            className="text-7xl md:text-9xl w-60 md:w-96 mr-10"
            data-aos="flip-up"
          >
            Releases.
          </h1>
        </div>
      </div>
*/}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 container flex flex-col justify-center items-center mt-10 my-5 px-5">
        {images.slice(0, visibleProducts).map((image, index) => (
          <a
            key={index}
            href={image.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-center shadow-lg"
            data-aos="fade-in"
          >
            <img
              src={image.imageUrl}
              alt={`Image ${index + 1}`}
              className="max-w-full transition-opacity duration-300 hover:opacity-70"
              loading="lazy"
              width="600"
              height="600"
            />
          </a>
        ))}
      </div>

      <div className="flex justify-end mr-5">
        {visibleProducts < images.length && (
          <button
            className="bg-white hover:bg-gray-100 px-4 py-2 border-2 border-solid border-black mb-5 my-1 w-48"
            onClick={displayMoreProducts}
          >
            <h1 className="text-xl">Show more releases ...</h1>
          </button>
        )}
      </div>
    </div>
  );
};

export default Releases;

import React from "react";
import { useEffect } from "react";
import { WritersData } from "../constants/Writers.js";

import AOS from "aos";
import "aos/dist/aos.css";

const Writers = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 container flex flex-col justify-center items-center mt-5 mb-10 px-5">
      {WritersData.map((writer, index) => (
        <a
          key={index}
          href={writer.linkUrl}
          className="text-center shadow-lg relative"
          data-aos="fade-in"
        >
          <img
            src={writer.imageUrl}
            alt={`Image ${index + 1}`}
            className="max-w-full transition-opacity duration-300 hover:opacity-70"
            loading="lazy"
            width="600"
            height="500"
          />

          <div className="text-2xl md:text-3xl absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 duration-300 transition-opacity">
            <h6 className="text-white"> {writer.imageText}</h6>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Writers;

import React from "react";
import "../App.css";
import {
  FaInstagram,
  FaFacebook,
  FaSoundcloud,
  FaTwitter,
} from "react-icons/fa";
import JohannaImage from "../images/johanna-ekholm-profile.webp";
function Johanna() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Johanna "Islet Oak" Ekholm</h1>
          <br />
          <h3 className="text-3xl italic">Songwriter</h3>
          <br />
          <p>
            Johanna is a versatile vocalist, songwriter and vocal producer with
            her roots in live music and vocal arrangements. She’s been spotted
            behind some of Swedens top artists such as Zara Larsson, Benjamin
            Ingrosso, Ana Diaz and Danny Saucedo to name a few.
          </p>
          <br />
          <p>
            Recent collabs includes artists / writers such as Dynoro, Reipet,
            Alex Lemirage, GYMBRO, Augustine, EEVA, Sirena, Wilder Gray, Alex
            D’Rosso, Avenza and more, as well as focusing on her artist project
            JEAN ROOT (soulful indiepop).
          </p>
          <br />
          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/johanna_ekholm_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={JohannaImage} className="artists__photo__size" />
          </div>
        </div>
      </div>
      <div className="spotify__playlist" data-aos="fade-right">
        <iframe
          src="https://open.spotify.com/embed/playlist/6DPoX5oAfV19JZEehHvQfA?utm_source=generator&theme=0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
      <div className="flex justify-center my-10" data-aos="fade-in">
        <iframe
          src="https://www.youtube.com/embed/DpQ1-_d4KSA?si=xPZH8Ssmz_BCbtqe"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="w-[90%] max-w-[800px] h-[400px]"
        ></iframe>
      </div>
    </div>
  );
}

export default Johanna;

import React from "react";
import Releases from "../components/Releases";

function Work() {
  return (
    <div className="flex flex-col items-center justify-center">
      <Releases />
    </div>
  );
}

export default Work;

import Elsa from "../images/elsa_1.webp";
import Adam from "../images/adam_1.webp";
import Alexander from "../images/alexander_1.webp";
import Gustav from "../images/gustav_1.png";
import Elias from "../images/elias_1.png";
import Karl from "../images/karl_flykt_1.webp";
import Johanna from "../images/johanna-ekholm.webp";
import Steerner from "../images/steerner.webp";

export const WritersData = [
  {
    imageUrl: Elsa,
    imageText: "Elsa Carmona Oljelund",
    linkUrl: "/elsa-carmona-oljelund",
  },
  {
    imageUrl: Adam,
    imageText: "Adam Woods",
    linkUrl: "/adam-woods",
  },
  {
    imageUrl: Alexander,
    imageText: "Alexander 'Baimz' Latila",
    linkUrl: "/alexander-latila",
  },
  {
    imageUrl: Gustav,
    imageText: "Gustav Nilsson",
    linkUrl: "/gustav-nilsson",
  },
  {
    imageUrl: Steerner,
    imageText: "Niklas Sterner aka Steerner",
    linkUrl: "/niklas-sterner",
  },
  {
    imageUrl: Elias,
    imageText: "Elias Öhberg",
    linkUrl: "/elias-ohberg",
  },
  {
    imageUrl: Johanna,
    imageText: "Johanna Ekholm",
    linkUrl: "johanna-ekholm",
  },
  {
    imageUrl: Karl,
    imageText: "Karl Flykt",
    linkUrl: "karl-flykt",
  },
];
